.App2 {
  text-align: center;
}

.App-logo2 {
  height: 40vmin;
  pointer-events: none;
}

@media_DISABLED (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header2 {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link2 {
  color: #61dafb;
}

@keyframes_DISABLED App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html {
   margin: 0px;
   height: 100%;
   width: 100%;
   background-color: black;
}

body {
   margin: 0px;
   min-height: 100%;
   width: 100%;
   background-color: black;
}

#ver-id {font-size: .8em; background-color : black; color: white; margin-top:20px;} 
#console-id {font-size: .8em; background-color : black; color: white;} 

#base {
  min-width: 600px;
  disabled_max-width: 600px;
  border: solid 0px red;
}

a {
  webkit-user-select: none; /* webkit (safari, chrome) browsers */
  moz-user-select: none; /* mozilla browsers */
  khtml-user-select: none; /* webkit (konqueror) browsers */
  ms-user-select: none; /* IE10+ */
}

a:hover {
text-decoration:none;
background-color:red;
}

a:active {
text-decoration:none;
background-color:purple;
}

.edge {
  border-radius: 30px;
}

.icon {background-color:#EEEEEE; 
  border-radius: 30px;
  display:inline-block;
  commentout-line-height:170px;
  min-height:160px;
  width: 160px;
  text-align: center;
  vertical-align: middle;
  margin:15px; 
  font-size:30px;
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
}