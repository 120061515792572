body {
  DISABLE_margin: 0;
  DISABLE_font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -DISABLE_webkit-font-smoothing: antialiased;
  -DISABLE_moz-osx-font-smoothing: grayscale;
}

code {
  DISABLE_font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
